// Internal: A collection of functions for managing the UTM params
// into and out of Local Storage.
export default class UTMManager {

  // Public: Takes a NextJS query object and returns a object with UTM params
  //  if they are present in the query string.
  static extractParamsFrom(query) {
    const utmObject = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content']
      .reduce( (accumulator, utm_param) => {
        if (query[utm_param]) { accumulator[utm_param] = query[utm_param] }
        return accumulator;
      }, {}
    );

    // utm_term can have multiple values so we use an array.
    if (query.utm_term) {
      utmObject.utm_term = query.utm_term.toLowerCase().split(' ');
    }

    return utmObject;
  }

  // Public: Stores an object of UTM params in Local Storage.
  static store(valuesObject = {}) {
    // Return early if the valuesObject is empty.
    if (Object.keys(valuesObject).length == 0) { return }

    // Overwrite any exiting UTM params in Local Storage
    // with the new valuesObject.
    const valuesString = JSON.stringify(valuesObject);
    try {
      localStorage.setItem( 'utm_object', valuesString);
    } catch(e) {
      // Access denied for some reason
    }
  }

  // Public: Retrieves an object of UTM params from Local Storage. Returns an
  // empty object if none are present.
  static retrieveValues() {
    let valuesString;
    try {
      valuesString = localStorage.getItem( 'utm_object');
    } catch(e) {
      valuesString = '{}';
    }
    return JSON.parse(valuesString) || {};
  }
}
