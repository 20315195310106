import PropTypes from 'prop-types';
import Link from 'next/link';

const HandDrawnButton = ({path, text, large, extraPadding, id, handleClick}) => {
  const query = {}

  return (
    <Link
      href={{ 
        pathname: path,
        query
      }} 
      passHref
    >
      <a
        className={`block hand-drawn-border-radii bg-red font-semibold text-white hover:bg-white hover:text-red self-center px-2 sm:px-4 transition-all duration-100 border-white text-center cursor-pointer ${large ? "text-2xl py-3" : "py-1"} ${extraPadding && "px-5 py-5"}`}
        id={id}
        onClick={handleClick}
      >
        {text}
      </a>
    </Link>
  );
};

HandDrawnButton.propTypes = {
  text: PropTypes.string,
  path: PropTypes.string,
  large: PropTypes.bool,
  extraPadding: PropTypes.bool,
  id: PropTypes.string,
  handleClick: PropTypes.func,
};

export default HandDrawnButton;
